import React, { ReactNode } from 'react'

import EmptyIcon from './EmptyIcon'

interface Props {
  description?: string
  title?: string
  className?: string
  icon?: React.ReactNode | React.ReactNode[]
  color?: string
  titlePosition?: 'top' | 'bottom'
  children?: ReactNode
}

const Empty: React.FC<Props> = ({
  description,
  title = 'No Data Found',
  children,
  icon,
  className = '',
  color,
  titlePosition = 'bottom',
}) => {
  return (
    <div className={`${className} flex flex-col text-center w-full`}>
      <div className={`${color ? color : 'fill-gray-200'} flex justify-center`}>
        {icon ? icon : <EmptyIcon />}
      </div>
      <h3
        className={`font-semibold mt-2 text-xl font-medium text-gray-900 ${
          titlePosition === 'top' && 'order-first'
        }`}
      >
        {title}
      </h3>
      {description && (
        <p className="mt-4 text-base text-gray-500">{description}</p>
      )}
      <div className="mt-6">{children}</div>
    </div>
  )
}

export default Empty
